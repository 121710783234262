import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/Clubs/LitreryClub/2024/Literary/1.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const StoryTellingGrade1and2 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Storytelling1and2/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Storytelling1and2/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Storytelling1and2/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Storytelling1and2/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 1.5,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 2,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 2,
        },
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                    STORY TELLING SESSION: 2024-25
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes 1 and 2  &  Date: Tuesday, 11 March 2025
                                </Typography>
                                {/* <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Reading is essential for those who seek to rise above the ordinary.” – Jim Rohn
                                    <br />
                                </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Department of Library, National Public School Yeshwanthpur, in association with Scholastic
                                    India organised a story telling session for Classes 1 and 2 on Tuesday, 11 March 2025. The event
                                    featured the renowned story teller, educator and actor Ms. Ramya Srinidhi as the esteemed guest
                                    narrator.
                                    <br></br>
                                    The aim of storytelling is to develop positive attitude towards among the students through
                                    imagination and language learning. Ms. Ramya brought the stories to life through her engaging
                                    actions, creative props, and interactive approach, captivating the children&#39;s imagination and
                                    encouraging their active participation throughout the session. The students enjoyed the session
                                    thoroughly.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “There is no greater agony than bearing an untold story inside you.”- Maya Angelou

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>


                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        {/* <Box marginBottom={4}>
                                    <Archive2023/>
                        </Box> */}
                        <Box marginBottom={4}>
                            <LiterarySidebar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default StoryTellingGrade1and2;